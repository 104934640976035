var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "lazy-load",
    { attrs: { item: _vm.studentExams } },
    [
      _c("v-simple-table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Name")]),
            _c("th", [_vm._v("Score Obtained")]),
            _c("th", [_vm._v("Date Appeared")]),
            _c("th"),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.studentExams, function (exam) {
            return _c("tr", { key: exam.student_id }, [
              _c("td", [_vm._v(_vm._s(_vm.students[exam.student_id].name))]),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(
                      exam.score
                        ? exam.score
                        : exam.score == 0
                        ? exam.score
                        : "Not evaluated"
                    ) +
                    " "
                ),
              ]),
              _c("td", [_vm._v(_vm._s(exam.start_at.split("T")[0]))]),
              _c(
                "td",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: exam.is_evaluated !== false,
                        color: "primary",
                        rounded: "",
                        to: {
                          name: "student-exam-details",
                          params: { studentExamId: exam.id },
                        },
                      },
                    },
                    [_vm._v("Evaluate")]
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }