var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "m-container",
        [
          _c("page-title", {
            staticClass: "mt-10",
            attrs: {
              "previous-location": "",
              "page-title": _vm.$route.meta.title,
            },
          }),
          _c("exam-student-list", {
            staticClass: "mt-4 ml-2",
            attrs: {
              "course-id": _vm.courseId,
              "batch-id": _vm.batchId,
              "exam-id": _vm.examId,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }