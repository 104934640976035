<template>
  <div>
    <m-container>
      <page-title
        previous-location
        :page-title="$route.meta.title"
        class="mt-10"
      ></page-title>

      <exam-student-list
        class="mt-4 ml-2"
        :course-id="courseId"
        :batch-id="batchId"
        :exam-id="examId"
      ></exam-student-list>
    </m-container>
  </div>
</template>

<script>
import ExamStudentList from "#ecf/exam/components/ExamStudentList";
export default {
  name: "StudentListPage",
  components: { ExamStudentList },
  props: {
    courseId: {
      type: String,
      required: true
    },
    batchId: {
      type: String,
      required: true
    },
    examId: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.student-list {
  h2 {
    font-family: Roboto, serif;
    font-size: 22px;
    font-weight: 500;
    color: $text;
    margin-bottom: 10px;
  }
  h3 {
    font-family: Roboto, serif;
    font-size: 18px;
    font-weight: 500;
    color: $text;
    margin-bottom: 4px;
  }
  p {
    font-family: Roboto, serif;
    margin-bottom: 14px !important;
    color: #000000;
    text-align: justify;
  }
}
.answer-grid {
  display: grid;
  grid-template-columns: auto auto;
}
</style>
