// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".student-list h2 {\n  font-family: Roboto, serif;\n  font-size: 22px;\n  font-weight: 500;\n  color: #3d3d3d;\n  margin-bottom: 10px;\n}\n.student-list h3 {\n  font-family: Roboto, serif;\n  font-size: 18px;\n  font-weight: 500;\n  color: #3d3d3d;\n  margin-bottom: 4px;\n}\n.student-list p {\n  font-family: Roboto, serif;\n  margin-bottom: 14px !important;\n  color: #000000;\n  text-align: justify;\n}\n.answer-grid {\n  display: grid;\n  grid-template-columns: auto auto;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
