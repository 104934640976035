<template>
  <!--  <v-container>-->
  <lazy-load :item="studentExams">
    <v-simple-table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Score Obtained</th>
          <th>Date Appeared</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="exam in studentExams" :key="exam.student_id">
          <td>{{ students[exam.student_id].name }}</td>
          <td>
            {{
              exam.score
                ? exam.score
                : exam.score == 0
                ? exam.score
                : "Not evaluated"
            }}
          </td>
          <td>{{ exam.start_at.split("T")[0] }}</td>
          <td>
            <v-btn
              :disabled="exam.is_evaluated !== false"
              color="primary"
              rounded
              :to="{
                name: 'student-exam-details',
                params: { studentExamId: exam.id }
              }"
              >Evaluate</v-btn
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </lazy-load>

  <!--  </v-container>-->
</template>

<script>
export default {
  name: "ExamStudentList",
  props: {
    courseId: {
      type: String,
      required: true
    },
    batchId: {
      type: String,
      required: true
    },
    examId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      studentExams: []
    };
  },
  computed: {
    students() {
      return this.$store.state.student.studentBatchMap[this.batchId];
    }
  },
  created() {
    this.fetchStudentList();
    this.fetchStudentExams();
  },
  methods: {
    async fetchStudentList() {
      try {
        await this.$store.dispatch("student/listBatchStudents", {
          courseId: this.courseId,
          batchId: this.batchId,
          partner_code: this.partner_code
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchStudentExams() {
      try {
        this.studentExams = await this.$store.dispatch("studentExam/getStudentExams", {
          examId: this.examId,
          batchId: this.batchId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    }
  }
};
</script>

<style scoped></style>
